<template>
    <div>
      <Header/>
      <div class="content_text_s">
        <!-- <router-link :to="{path:'../'}">
          <img class="content_img_1_s" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_2_s" src="../../assets/my/time_icon.png">
        <div calss="content_study_s">{{activation_record}}</div>
      </div>
      <div v-for="item in list" :key="item">
        <router-link :to="{path:'/my/activation_record/'+item.id}">
          <div class="listitem-item">
            <div class="listitem-item-text-con">
              <p class="p_1">{{item.read_title}}</p>
              <p class="p_2">{{item.created_at}}</p>
            </div>
          </div>
        </router-link>  
      </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiUserClockLogsList} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      list:[
      ],
      activation_record:'',
    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
        this.activation_record = this.$zh_ft('activation_record');
    }else{
        this.activation_record = this.$zh_jt('activation_record');
    }

    apiUserClockLogsList({
      clock_type_id:3,
      lang_id:lang_id
    }).then(res=>{
      if(res.data){
        this.list = res.data.data;
        for(let i = 0; i<res.data.data.length; i++){
         res.data.data[i].created_at = res.data.data[i].created_at.split(' ')[0];
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  }
}
</script>
<style>
p{
  margin:0;
}
.p_2{
  margin-top: 3px;
  font-family: Helvetica Neue;
  font-size: 14px;
  text-align: left;
  color: #fff;
  opacity: .7;
}
.p_1{
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 17px;
  text-align: left;
  color: #fff;
}
.listitem-item-text-con{
  margin-left: 15px;
  margin-right: 15px;
}
.listitem-item{
  height: 72px;
  border-bottom: 1px solid #fff;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: initial;
}
.content_study_s{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
 .content_img_1_s{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2_s{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
.content_text_s{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
</style>